import { createContentfulComponent } from "frontend/contentful/lib/create-contentful-component";
import { BigGridListEntry } from "frontend/contentful/schema/blocks";
import {
  BigGridList,
  BigGridListProps,
} from "design-system/components/blocks/big-grid-list/big-grid-list";
import { RenderContentfulRichText } from "../utils/render-contentful-rich-text";
import { createComponentHeaderProps } from "../primitives/component-header.props";
import { createMediaAssetProps } from "../primitives/media-asset.props";
import { createCtaLinkProps } from "../primitives/cta-link.props";
import { useContentful } from "frontend/hooks/use-contentful";
import { Alignment } from "design-system/types/types";

interface BigGridListOptions {
  alignment?: Alignment;
  headerAbove?: boolean;
}

export const ContentfulBigGridList = createContentfulComponent<
  BigGridListEntry,
  BigGridListOptions
>(({ entry, options, createEditAttributes }) => {
  const { getEntry, getEntries } = useContentful();
  const headerAbove = options?.headerAbove ?? false;

  const props: BigGridListProps = {
    headerContent: createComponentHeaderProps(entry.fields.header),
    title: entry.fields.title,
    subtitle: entry.fields.subtitle,
    mediaAlignment: entry.fields.mediaAlignment,
    align: entry.fields.alignment,
    items: [],
  };

  getEntries(entry.fields.listItems)?.forEach((item) => {
    const title = (
      <RenderContentfulRichText
        document={item.fields.title}
        disableParagraphs
      />
    );
    const subtitle = (
      <RenderContentfulRichText
        document={item.fields.description}
        disableParagraphs
      />
    );

    if (title && subtitle) {
      props.items.push({
        title,
        subtitle,
        editAttributes: {
          title: createEditAttributes({ entry: item, fieldId: "title" }),
          description: createEditAttributes({
            entry: item,
            fieldId: "description",
          }),
        },
      });
    }
  });

  const media = getEntry(entry.fields.media);

  const mediaAssetProps = createMediaAssetProps(media);
  if (mediaAssetProps) props.media = mediaAssetProps;

  const cta = getEntry(entry.fields.cta);
  if (cta) props.cta = createCtaLinkProps(cta);

  if (options?.alignment) {
    props.align = options.alignment;
  }

  if (headerAbove && props.headerContent && entry.fields.title) {
    props.HeadingLevelIntro = "h4";
    props.HeadingLevelItems = "h5";
  } else if (headerAbove && props.headerContent) {
    props.HeadingLevelItems = "h4";
  } else if (
    (headerAbove && entry.fields.title) ||
    (props.headerContent && entry.fields.title)
  ) {
    props.HeadingLevelIntro = "h3";
    props.HeadingLevelItems = "h4";
  } else if (headerAbove || props.headerContent) {
    props.HeadingLevelItems = "h3";
  } else if (entry.fields.title) {
    props.HeadingLevelIntro = "h2";
    props.HeadingLevelItems = "h3";
  } else {
    props.HeadingLevelItems = "h2";
  }

  return <BigGridList {...props} />;
});
