import "./big-grid-list.scss";

import cn from "clsx";
import {
  ComponentHeader,
  ComponentHeaderProps,
} from "design-system/components/primitives/component-header/component-header";
import {
  CtaLink,
  CtaLinkProps,
} from "design-system/components/primitives/cta-link/cta-link";
import {
  MediaAsset,
  MediaAssetProps,
} from "design-system/components/primitives/media-asset/media-asset";
import {
  Alignment,
  EditAttributes,
  MediaAlignment,
} from "design-system/types/types";
import { ReactNode } from "react";

export interface BigGridListItemProps {
  title: ReactNode;
  subtitle: ReactNode;

  editAttributes?: {
    title?: EditAttributes;
    description?: EditAttributes;
  };
}

export interface BigGridListProps {
  align?: Alignment;
  items: Array<BigGridListItemProps>;
  headerContent?: ComponentHeaderProps;
  title?: string;
  subtitle?: string;
  media?: MediaAssetProps;
  mediaAlignment?: MediaAlignment;
  cta?: CtaLinkProps;
  HeadingLevelIntro?: "h2" | "h3" | "h4";
  HeadingLevelItems?: "h2" | "h3" | "h4" | "h5";
}

/**
 * For displaying a list of text items in a visually interesting way.
 *
 * ## Usage
 * Only use either headerContent or title and subtitle, not both.
 *
 * ## See it in use on...
 * - A [landing page](/story/example-pages-landing-pages-card-topper--story)
 *
 * - **`id: CB-005-002-00`**
 * - **`data-region: cb__grid-lists__big`**
 */
export function BigGridList({
  align = "full",
  items,
  headerContent,
  title,
  subtitle,
  media,
  mediaAlignment = "left",
  cta,
  HeadingLevelIntro = "h2",
  HeadingLevelItems = "h3",
}: BigGridListProps) {
  const wrapperClass = cn(`hbs-global-align-${align}`);
  const withIntro = title || subtitle;
  const gridListClass = cn(
    "hbs-big-grid-list",
    media && "hbs-big-grid-list--has-media",
    items.length > 4 || items.length === 3
      ? "hbs-big-grid-list--has-media--3up"
      : "hbs-big-grid-list--has-media--2up",
    mediaAlignment === "right" && "hbs-big-grid-list--aligned-right",
    (headerContent || withIntro) && "hbs-big-grid-list--has-header",
  );

  return (
    <div className={wrapperClass} data-region="cb__grid-lists__big">
      {headerContent && <ComponentHeader {...headerContent} anchored={true} />}

      <div className={gridListClass}>
        {media && (
          <div className="hbs-big-grid-list__media">
            <MediaAsset {...media} />
          </div>
        )}

        <div className="hbs-big-grid-list__content">
          {withIntro && (
            <div className="hbs-big-grid-list__intro">
              {title && (
                <HeadingLevelIntro className="hbs-text-h4">
                  {title}
                </HeadingLevelIntro>
              )}
              {subtitle && (
                <div className="hbs-big-grid-list__subtitle hbs-text-h4">
                  {subtitle}
                </div>
              )}
            </div>
          )}
          <ul className="hbs-big-grid-list__list">
            {items.map(({ title, subtitle, editAttributes }, index) => (
              <li className="hbs-big-grid-list__item" key={index}>
                <HeadingLevelItems
                  {...editAttributes?.title}
                  className="hbs-big-grid-list__item-title"
                >
                  {title}
                </HeadingLevelItems>
                <p
                  {...editAttributes?.description}
                  className="hbs-big-grid-list__item-subtitle"
                >
                  {subtitle}
                </p>
              </li>
            ))}
          </ul>

          {cta && (
            <div className="hbs-big-grid-list__cta">
              <CtaLink {...cta} className="hbs-big-grid-list__cta-link" />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
